<template>
  <div class="saidbar-links">
    <template v-for="(item, index) in arrOrderId">
      <div @click="getOrderInfo(item,index)" class="link-id">
<!--        <router-link  :to="'/?hash=' + GET.hash + '&customer_id=' + GET.customerId " class="link-order">{{'Заказ№ ' + item}}</router-link>-->
        <router-link  :to="'/?customer_id=' + GET.customerId " class="link-order">{{'Заказ№ ' + item}}</router-link>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "menuLink",
  props: ['arrOrderId', 'GET'],
  methods: {
    linkStyleCSS(index){
      let links = document.querySelectorAll('.link-id')
      for(let i = 0; i< links.length; i++){
        links[i].classList.remove('active-link')
      }
      links[index].classList.add('active-link')
    },
    getOrderInfo (item,index, flag=true) {
      // console.log('click')
      this.$emit('loderMenu', true)
      let formData = new FormData();
      formData.append('order_id', item);
      axios.post("https://service.monro-art.ru/vendor/getProducts.php",
          formData
          , {
            headers: {
              "Content-type": "application/json",
            },
          })
          .then((res) => {
            let orders = res.data.order_products;
            // console.log('res')
            // console.log(res)
            // console.log('orders')
            // console.log(orders)
            let arrOrdersInfo = []
            let priceArr = []
            let sumPrice = 0;

            let orderForm ={
              number: item,
              review: res.data.review,
              wishes: res.data.wishes,
            }

            for(let order of orders){
              let orderElem = {
                photo: order.approve_photo,
                name: order.name,
                id: order.id,
                price : order.rub_total_amount,
                size: order.size_name,
                index: index,
                img: JSON.parse(order.original_photo),
              }
              // console.log('FOORM')
              // console.log(res.data.wishes)
              // console.log(res.data.review)
              if(orderElem.img){
                arrOrdersInfo.push(orderElem);
              }
              if(order.name){
                priceArr.push({ name: order.name, price: order.price, id: order.id})
              }
            }
            for(let price of priceArr){
              sumPrice += price.price
            }
            this.$emit('displayBlock', true)
            // this.$emit('flagLinks', true)
            this.$emit('orderIdForm', this.arrOrderId[index])
            this.$emit('orderForm', orderForm)
            this.$emit('sumPrice', sumPrice)
            this.$emit('getOrderInfo', arrOrdersInfo)
            this.$emit('getPriceArr', priceArr)
          }).catch(e => console.log(e));

      this.linkStyleCSS(index)
      if(flag){
        this.$root.$emit('contentVue')
      }

    },
    activateLink(){
      // console.log('this.arrOrderId')
      // console.log(this.arrOrderId)
      let links = document.querySelectorAll('.link-id')
      // console.log('links')
      // console.log(links)
      let index = 0;
      let flagClass = false
      for(let i = 0; i< links.length; i++){

        if(links[i].classList.contains('active-link')) {
          flagClass = true;
          index = i
          this.getOrderInfo(this.arrOrderId[index],index, false);
        }
      }
      if(!flagClass){
        links[0].classList.add('active-link');
        this.getOrderInfo(this.arrOrderId[index],index);
      }
    },
    showLoader(){

    }
  },
  mounted: function () {
    // this.activateLink()
    this.$root.$on('menuLinkVue', this.activateLink)
    // this.$root.$on('menuLinkVue', () => {
    //   let links = document.querySelectorAll('.link-id')
    //   let index = 0;
    //   let flagClass = false
    //   for(let i = 0; i< links.length; i++){
    //     if(links[i].classList.contains('active-link')) {
    //       flagClass = true;
    //       index = i
    //       this.getOrderInfo(this.arrOrderId[index],index, false);
    //     }
    //   }
    // })
  },
  updated() {
    this.activateLink()
  }
}
</script>

<style>
  saidbar-links{
      display: flex;
      flex-direction: column;
  }

  .link-id{
    border-bottom: 1px solid rgba(0,0,0,.125);
    padding: 8px 16px;

    transition: background-color .2s linear;

  }

  .link-order{
    text-decoration: none;
    color: #212529;
  }

  .link-id:hover{
    background-color: rgba(0,0,0,.03);
  }

  .link-id:active{
    background-color: rgba(0, 0, 0, 0.09);

  }

  .active-link{
    background-color: rgba(0,0,0,.03);
  }
  @media(max-width: 600px) {
    .saidbar-links{
      text-align: center;
    }
  }
</style>